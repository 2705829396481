import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";

const SearchSort = ({ context }) => {
  const {
    searchSort,
    handleSearchSortChange,
    sortRadioInputs,
    initialState
  } = useContext(context);

  return (
    <fieldset>
      <Form.Group className="d-flex flex-column flex-lg-row align-items-lg-center">
        <Form.Label className="font-weight-bold m-0">Sort by:</Form.Label>
        {sortRadioInputs.map(({ label, value }) => (
          <Form.Check
            key={value}
            id={label}
            className={`ml-0 mt-3 mb-0 ml-lg-4 mt-lg-0 ${searchSort === value ? "active" : ""}`}
            label={label}
            checked={searchSort === value}
            type="radio"
            custom
            onChange={handleSearchSortChange.bind(null, value)}
          />
        ))}
      </Form.Group>
    </fieldset>
  );
};

export default SearchSort;