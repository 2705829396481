import React, { useContext } from "react";

import SearchSort from "./SearchSort";
import SearchCard from "./SearchCard";
import Pagination from "../../components/Pagination/Pagination";

const SearchWidget = ({ context }) => {
  const {
    searchResults,
    paginationDefaults: { perPage },
    entityUrl
  } = useContext(context);
  const renderData = ({ item, item: { entityId } }) => <SearchCard key={entityId} className="fadeInUp" data={item} />;

  return (
    <>
      <SearchSort context={context} />
      <Pagination
        scrollContainerRef="self"
        className="mt-3"
        perPage={perPage}
        data={searchResults}
        renderData={renderData}
        currentPath={entityUrl.path}
      />
    </>
  );
};

export default SearchWidget;