import { useState, useEffect } from "react";
import usePreviousHook from "./usePrevious";

export default ({ queryUpdater, sortUpdater }) => {
  const [query, updateQuery] = useState(null);
  const [sort, updateSort] = useState(null);
  const previousQueryParam = usePreviousHook(query);
  const previousSortParam = usePreviousHook(sort);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get("query");
    const sortParam = urlParams.get("sort");

    if (previousQueryParam !== queryParam) {
      queryUpdater(queryParam, sortParam);
    } else {
      if (previousSortParam !== sortParam) {
        sortUpdater(sortParam);
      }
    }

    updateQuery(queryParam);
    updateSort(sortParam);
  }, [typeof window !== "undefined" ? window.location.search : null]);
}