import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Link } from '../../common';

const EmptySearch = ({ header, text }) => {
  const data = useStaticQuery(graphql`
    {
      drupal {
        nodeQuery(filter: { conditions: [{ field: "type", value: "home_page", operator: EQUAL }] }) {
          entities {
            ... on Drupal_NodeHomePage {
              fieldPopularTopics {
                entity {
                  ...popularTopics
                }
              }
            }
          }
        }
      }
    }
  `);

  const fieldPopularTopics = data?.drupal?.nodeQuery?.entities[0].fieldPopularTopics || [];

  return (
    <div className="empty-search">
      <h2>{header}</h2>
      <p className="mt-3">{text}</p>
      <ul>
        {fieldPopularTopics
          .filter(
            ({
              entity: {
                fieldTopicsLink: { entity },
              },
            }) => entity
          )
          .map(({ entity: { entityId, fieldTopicsLink: { entity: { entityLabel, entityUrl: { path } } } } }) => (
            <li key={entityId} className="popular-topic">
              <Link href={path}>{entityLabel}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default EmptySearch;
