import React from "react";
import Moment from "react-moment";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Link } from "../../common";
import { formatDateMMMDYYYY } from '../../lib/helpers/date';

const SearchCard = ({
  data: {
    entityUrl: { path },
    fieldIntro,
    fieldH1Hero,
    fieldFaqIntro,
    fieldPageTitle,
    title,
    sortDate,
    breadcrumbs,
    metaTags
  },
  className = ""
}) => {
  const header =
    fieldIntro?.entity?.fieldHeadingCopy ||
    fieldH1Hero?.entity?.fieldHeadingCopy ||
    fieldFaqIntro?.entity?.fieldHeadingCopy ||
    fieldPageTitle ||
    title

  return (
    <div className={`search-card pt-4 pb-4 ${className}`}>
      {breadcrumbs?.length > 0 && (
        <Breadcrumbs breadcrumbs={breadcrumbs} noContainers={true} />
      )}

      <Link href={path} className="d-inline-block font-weight-bold mt-4">{header}</Link>

      <div className="d-flex mt-3">
        <p>{metaTags?.fieldMetadataDescription}</p>
      </div>

      {/* <Moment date={sortDate} format="MMMM D, YYYY" /> */}
      {formatDateMMMDYYYY(sortDate)}
    </div>
  );
};

export default SearchCard;