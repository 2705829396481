import React, { useContext, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import SearchInput from './SearchInput';

const SearchForm = ({ context, className = '' }) => {
  const { initialSearchInput, searchInput, handleSearchInputChange, handleResetClick, handleSubmit } = useContext(
    context
  );
  const searchPageInput = useRef();

  return (
    <Form className={`${className}`} onSubmit={(e) => handleSubmit(e, searchPageInput)}>
      <Container className="p-0">
        <Row className="align-items-center">
          <Col lg={9} className="mb-4 mb-lg-0">
            <SearchInput
              inputClassName="pr-7"
              ref={searchPageInput}
              value={searchInput}
              onChange={handleSearchInputChange}
              onResetClick={handleResetClick}
            />
          </Col>
          <Col className="pl-lg-1">
            <Button
              className="font-weight-medium w-100 px-0"
              type="submit"
              disabled={initialSearchInput === searchInput}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default SearchForm;
