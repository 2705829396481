import React, { createContext, useReducer } from 'react';
import { graphql } from 'gatsby';
import Fuse from 'fuse.js';

import useSearchParams from '../../hooks/useSearchParams';

import { MainContainer, PageContainer, Heading } from '../../common';
import SideBar from '../../components/SideBar/SideBar';
import SearchForm from '../../components/Search/SearchForm';
import SearchWidget from '../../components/Search/SearchWidget';
import EmptySearch from '../../components/Search/EmptySearch';

import { generateDateFormats } from '../../lib/helpers/date';
import { sortItems } from '../../lib/helpers/util';
import { styleVars } from '../../lib/helpers/style';

export const SearchContext = createContext({});

// TODO: check to make sure search params are up to date before hard launch
export const query = graphql`
  query($nid: String!) {
    drupal {
      searchPage: nodeById(id: $nid) {
        ... on Drupal_NodeSearchPage {
          fieldSearchH1
        }
      }
      searchItems: nodeQuery(
        limit: 1000
        filter: {
          conditions: [
            { field: "type", operator: NOT_EQUAL, value: "search_page" }
            { field: "type", operator: NOT_EQUAL, value: "home_page" }
            { field: "type", operator: NOT_EQUAL, value: "alert_toast" }
            { field: "status", operator: EQUAL, value: "1" }
          ]
        }
      ) {
        entities {
          entityId
          entityChanged
          entityBundle
          entityUrl {
            path
          }
          ...mainLandingContent
          ...childPageContent
          ...postHubContent
          ...postContent
          ...officeHubContent
          ...officeContent
          ...faqContent
          ...formContent
          ...contactUsContent
        }
      }
    }
  }
`;

const weightCategories = [
  {
    weight: 0.999,
    keys: [
      'fieldIntro.entity.fieldHeadingCopy',
      'fieldH1.entity.fieldHeadingCopy',
      'fieldH1Hero.entity.fieldHeadingCopy',
      'fieldFaqIntro.entity.fieldHeadingCopy',
      'fieldPageTitle',
      'title',
    ],
  },
  {
    weight: 0.8,
    keys: [
      'metaTags.fieldMetadataTitle',
      'metaTags.fieldMetadataDescription',
      'fieldIntro.entity.fieldFormattedParagraph.processed',
      'fieldH1.entity.fieldFormattedParagraph.processed',
      'fieldH1Hero.entity.fieldFormattedParagraph.processed',
      'fieldFaqIntro.entity.fieldFormattedParagraph.processed',
      'fieldAuthoredSections.entity.fieldText.processed',
      'fieldAuthoredSections.entity.fieldAccordionItem.entity.fieldAccordionText.processed',
      'fieldFormCards.entity.fieldFormCardSerial',
      'fieldFormCards.entity.fieldFormCardTitle',
      'fieldFormCards.entity.fieldFormCardDescription.processed',
    ],
  },
  {
    weight: 0.7,
    keys: ['fieldTags.entity.entityLabel'],
  },
  {
    weight: 0.6,
    keys: [
      'fieldCtas.entity.fieldTitle',
      'fieldAuthoredSections.entity.fieldHeadingCopy',
      'fieldAuthoredSections.entity.fieldAccordionItem.entity.fieldAccordionHeader',
      'fieldAuthoredSections.entity.fieldLink.title',
      'fieldAuthoredSections.entity.fieldQuote',
      'fieldAuthoredSections.entity.fieldAuthor',
      'fieldAuthoredSections.entity.fieldFactoidTitle',
      'fieldAuthoredSections.entity.fieldFactoidBody',
      'fieldOfficeAddress.addressLine1',
      'fieldOfficeAddress.addressLine2',
      'fieldOfficeAddress.locality',
      'fieldOfficeAddress.postalCode',
      'fieldPhoneNumber',
      'fieldOfficeHours.processed',
      'fieldOfficeServices.entity.name',
      'fieldOfficeNotice.entity.fieldNoticeText',
    ],
  },
  {
    weight: 0.5,
    keys: ['createdDates', 'changedDates'],
  },
];

const commonDateFormats = [
  'MMMM D, YYYY',
  'MMMM D YYYY',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'M/D/YYYY',
  'M-D-YYYY',
  'MM/DD/YY',
  'MM-DD-YY',
  'M/D/YY',
  'M-D-YY',
  'YYYY-MM-DD',
];

const searchOptions = {
  includeScore: true,
  threshold: 0.4,
  distance: 1000,
  keys: weightCategories.reduce((acc, category) => {
    category.keys.forEach((key) => acc.push({ name: key, weight: category.weight }));

    return acc;
  }, []),
};

const sortKeysMap = {
  relevant: ['score'],
  recent: ['item', 'sortDate'],
};

const sortRadioInputs = [
  { label: 'Most relevant', value: 'relevant' },
  { label: 'Most recent', value: 'recent' },
];

const paginationDefaults = {
  perPage: 10,
};

const initialState = {
  initialSearchInput: '',
  searchInput: '',
  searchSort: 'relevant',
  searchResults: null,
  triggerRender: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE_SEARCH':
      return { ...state, ...payload };
    case 'UPDATE_SEARCH_INPUT':
      return { ...state, searchInput: payload };
    case 'TRIGGER_RENDER':
      return { ...state, triggerRender: payload };
    default:
      return state;
  }
};

const Search = ({
  data: {
    drupal: { searchPage, searchItems },
  },
  pageContext: { entityUrl, nodeDataMap },
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { initialSearchInput, searchInput, searchResults } = state;
  const { fieldSearchH1 } = searchPage;

  const transformedSearchItems = searchItems.entities.map((searchItem) => {
    if (searchItem.entityCreated) {
      searchItem.createdDates = generateDateFormats(commonDateFormats, searchItem.entityCreated);
    }

    if (searchItem.entityChanged) {
      searchItem.changedDates = generateDateFormats(commonDateFormats, searchItem.entityChanged);
    }

    searchItem.sortDate = searchItems.entityBundle === 'post' ? searchItem.entityCreated : searchItem.entityChanged;

    return { ...searchItem, ...nodeDataMap[searchItem.entityId] };
  });

  const fuse = new Fuse(transformedSearchItems, searchOptions);

  const processSortValue = (sort) => {
    const urlParams = new URLSearchParams(window.location.search);

    let paramString;

    if (Object.keys(sortKeysMap).includes(sort)) {
      return sort;
    }

    urlParams.delete('sort');

    paramString = urlParams.toString();

    window.history.replaceState(null, null, paramString.length ? `?${paramString}` : `${entityUrl.path}/`);

    return initialState.searchSort;
  };

  const updateSearch = (query, sort) => {
    if (query) {
      const sortValue = processSortValue(sort);
      const sortOptions = {
        sortKeys: sortKeysMap[sortValue],
        ascend: sortValue === 'relevant',
      };

      dispatch({
        type: 'UPDATE_SEARCH',
        payload: {
          initialSearchInput: query,
          searchInput: query,
          searchSort: sortValue,
          searchResults: sortItems(fuse.search(query), sortOptions),
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_SEARCH',
        payload: {
          initialSearchInput: initialState.initialSearchInput,
          searchInput: initialState.searchInput,
          searchSort: initialState.searchSort,
          searchResults: initialState.searchResults,
        },
      });
    }
  };

  const updateSearchSort = (sort) => {
    const sortValue = processSortValue(sort);
    const sortOptions = {
      sortKeys: sortKeysMap[sortValue],
      ascend: sortValue === 'relevant',
    };

    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        searchSort: sortValue,
        searchResults: searchResults && sortItems(searchResults, sortOptions),
      },
    });
  };

  const triggerRender = () => dispatch({ type: 'TRIGGER_RENDER', payload: true });

  const handleSearchInputChange = (e) => dispatch({ type: 'UPDATE_SEARCH_INPUT', payload: e.target.value });

  const handleSearchSortChange = (value) => {
    const urlParams = new URLSearchParams(window.location.search);

    let paramString;

    if (value === initialState.searchSort) {
      urlParams.delete('sort');
    } else {
      urlParams.set('sort', value);
    }

    paramString = urlParams.toString();

    window.history.pushState(null, null, paramString.length ? `?${paramString}` : `${entityUrl.path}/`);
    triggerRender();
  };

  const handleResetClick = () => dispatch({ type: 'UPDATE_SEARCH_INPUT', payload: '' });

  const handleSubmit = (e, ref) => {
    e.preventDefault();

    if (initialSearchInput !== searchInput) {
      const urlParams = new URLSearchParams(window.location.search);

      if (searchInput) {
        urlParams.set('query', searchInput);
        urlParams.delete('page');
        window.history.pushState(null, null, `?${urlParams.toString()}`);
      } else {
        window.history.pushState(null, null, `${entityUrl.path}/`);
      }

      ref.current.blur();
      triggerRender();
    }
  };

  useSearchParams({ queryUpdater: updateSearch, sortUpdater: updateSearchSort });

  return (
    <SearchContext.Provider
      value={{
        ...state,
        handleSearchInputChange,
        handleSearchSortChange,
        handleResetClick,
        handleSubmit,
        initialState,
        sortRadioInputs,
        paginationDefaults,
        entityUrl,
      }}
    >
      <MainContainer row className={`search-page pt-8 pt-${styleVars.mobileBreakpoint}-9 pb-13`}>
        <SideBar />

        <PageContainer>
          <Heading as="h1" styledAs="h1" color="darkYellow" className="mb-4">
            {fieldSearchH1}
          </Heading>

          <SearchForm className="border-bottom pb-8 pb-xl-13 mb-4" context={SearchContext} />

          {searchResults ? (
            searchResults.length > 0 ? (
              <SearchWidget context={SearchContext} />
            ) : (
              <EmptySearch
                header="We couldn't find what you were looking for."
                text="Try using different search terms or browse our most popular services below."
              />
            )
          ) : (
            <EmptySearch
              header="What are you looking for?"
              text="Enter your search or browse our most popular services below."
            />
          )}
        </PageContainer>
      </MainContainer>
    </SearchContext.Provider>
  );
};

export default Search;
